import React from 'react'
import './footer.css'
import footer_img from '../../assets/header/logo.png'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div className='outer-footer'>
                <div className='footer-img'>
                    <img id='footer-img' src={footer_img} alt='footer-logo' />
                    
                </div>
                <div className='footer-left'>
                    <div className='footer-list'>
                        <div className='list-head'>
                            Quick Links
                        </div>
                        <ul className='list-body'>
                            <li className='list-items'> <Link to="/">Home</Link> </li>
                            <li className='list-items'> <Link to="/pricing">Pricing</Link></li>
                            <li className='list-items'><a target='_blank' href='https://blog.prepbubble.com/'>Current Affairs</a></li>
                            <li className='list-items'> <Link to="/terms-and-conditions">Terms and conditions</Link></li>
                            <li className='list-items'><Link to="/privacy"> Privacy Policy</Link></li>
                        </ul>
                    </div>
                    <div className='footer-list'>
                        <div className='list-head'>
                            Social
                        </div>
                        <ul className='list-body'>
                            <li className='list-items'><a href="https://t.me/aspirant_learning" target="_blank" rel="noreferrer" >Telegram</a></li>
                            <li className='list-items'><a href="https://www.facebook.com/profile.php?id=61563608871441" target="_blank" rel="noreferrer">Facebook</a></li>
                            <li className='list-items'><a href='https://www.instagram.com/prepbubble_upsc?igsh=a2I4azR4Z3dwb2Zk' target="_blank" rel="noreferrer">Instagram</a></li>
                            <li className='list-items'><a href='https://www.linkedin.com/company/prepbubble/' target="_blank">LinkedIn</a></li>
                        </ul>
                    </div>

                    <div className='footer-list'>
                        <div className='list-head'>
                            Contact
                        </div>
                        <ul className='list-body'>
                            <li className='list-items'><strong>Enquiries & suggestions:</strong><br></br><a href="mailto:info@aspirantlearning.com"> prepbubble.info@gmail.com</a> </li>
                            {/* <li className='list-items'><strong>For issues:</strong><br></br><a href='mailto:info@aspirantlearning.com'> support@aspirantlearning.com</a> </li> */}
                            {/* <li className='list-items'><strong>Phone: </strong><a href='tel:918921122082'>+91 8921-122082</a></li> */}
                            <li className='list-items'>Location: Kochi-682016, India </li>
                            
                        </ul>
                    </div>
                    {/* <div className='footer-text'>
                        <div className='list-head'>Contact</div>
                        <div className='enquiry-text list-items'>Enquiries & suggestions: <a href='mailto:info@aspirantlearning.com'></a>info@aspirantlearning.com</div>
                        <button className='btn-footer btn'>I want to know more</button>
                    </div> */}
                </div>
            </div>
            <div className='lower-footer'> © 2022 All Rights Reserved by Leasys Technologies Pvt Ltd</div>
        </>
    )
}

export default Footer