import React from "react";
import "./header.css";
import { useState, useEffect } from "react";
import logo from "../../assets/header/logo.png";
import Pricing from "../../pages/Home/Pricing/Pricing";
import { Link } from "react-router-dom";

function Header() {
  let a = 0;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isAuthenticated"));
  }, []);

  function homepage() {
    window.location.href = "/";
  }

  return (
    <>
      <div className="upper-nav ">
        {" "}
        <div className="upper-nav-text">
          Limited Time Offer Signup and Learn for Free
        </div>{" "}
        <Link className="sign-up" to="/login">
          Signup Now
        </Link>{" "}
      </div>
      <div className="lower-nav">
        <img
          onClick={() => homepage()}
          src={logo}
          alt=""
          className="logo-img"
        />
        <ul className="nav-ul">
          <li className="nav-li">
            {" "}
            <Link to="/">Home</Link>
          </li>
          {isLoggedIn && (
            <li className="nav-li">
              {" "}
              <Link to="/dashboard">Dashboard</Link>{" "}
            </li>
          )}
          <li className="nav-li">
            {" "}
            <Link to="/pricing">Pricing</Link>{" "}
          </li>
          <li className="nav-li">
            {" "}
            <Link to="/faq">FAQ</Link>{" "}
          </li>
          <li className="nav-li">
            <a target="_blank" href="https://blog.prepbubble.com/"> Current Affairs</a>
          </li>
          {/* <li className='nav-li'>Have Questions</li> */}
        </ul>

        {isLoggedIn ? (
          <Link to="/dashboard">
          <button className="btn-nav btn px-2 text-[20px]">
            Dashboard
          </button>
        </Link>
        ) : (
          <Link to="/login">
            <button className="btn-nav btn px-2 text-[20px]">
              Signup / Login
            </button>
          </Link>
        )}
      </div>
      <div className="hamburger">
        <img src={logo} alt="" className="logo-img" id="l" />
        <div
          id="ham"
          onClick={() => {
            if (a % 2 == 0) {
              // document.getElementsByClassName('mobile')[0].style.height = '100vh'
              document.getElementsByClassName("mobile")[0].style.left = "50vw";
              document.getElementsByClassName("mobile")[0].style.display =
                "block";
              document.getElementsByTagName("body")[0].style.overflowY =
                "hidden";
              // document.getElementById('arr1').style.visibility = 'hidden'
              setTimeout(() => {
                // document.getElementById('arr1').style.visibility = 'hidden'
              }, 250);
              // document.getElementsByClassName('mobile')[0].style.width = '100vw'
            } else {
              // document.getElementsByClassName('mobile')[0].style.height = '0vh'
              document.getElementsByClassName("mobile")[0].style.left = "100vw";
              document.getElementsByTagName("body")[0].style.overflowY =
                "scroll";
              setTimeout(() => {
                // document.getElementById('arr1').style.visibility = 'visible'
              }, 250);
              // document.getElementsByClassName('mobile')[0].style.display = '100vw'
              // document.getElementsByClassName('mobile')[0].style.width = '0vw'
            }
            a++;
            // console.log('event')
          }}
        >
          <svg viewBox="0 0 100 80" width="50" height="20">
            <rect width="50" x="50" height="10"></rect>
            <rect y="25" x="25" width="75" height="10"></rect>
            <rect y="50" width="100" height="10"></rect>
          </svg>
        </div>
      </div>
      <ul className="nav-ul mobile">
        <li className="nav-li mobi">
          <Link to="/">Home</Link>
        </li>
        <li className="nav-li mobi">
          <Link to="/pricing">Pricing</Link>
        </li>
        <li className="nav-li mobi">
          <a href="https://aspirantlearning.com/"> Current Affairs</a>
        </li>
        {/* <li className='nav-li mobi'>Have Questions</li> */}
      </ul>
    </>
  );
}

export default Header;
