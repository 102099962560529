import React from "react";
import "./content.css";
import { useState, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import Graph from "./DashboardGraph.js";
import info from "../../assets/dashboard/info.svg";
import DashboardDivision from "./DashboardDivision.js";
import runningMan from "../../assets/dashboard/runningMan.svg";
import greenTick from "../../assets/dashboard/greenTick.svg";
import purpleTick from "../../assets/dashboard/purpleTick.svg";
import Slider from "react-slick";
import whiteTick from "../../assets/dashboard/whiteTick.svg";
import { Link } from "react-router-dom";
import { notification } from "antd";

const Content = ({
  userName,
  dashboardHomeModal,
  setDashboardHomeModal,
  testHistory,
  testCompleted,
  allowedDailyTests,
  completedTestsToday,
}) => {
  const [completedTasks, setCompletedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(2);
  const [percent, setPercent] = useState(null);

  const openNotification = (placement) => {
    console.log("helo");
    notification.open({
      message: `Notification `,
      description: "Completed all tests for today",
    });
  };

  useEffect(() => {
    setPercent((completedTestsToday / allowedDailyTests) * 100);
    console.log(testHistory);
  }, [allowedDailyTests]);

  const n = 3;
  const m = 2;

  const [activeGroups, setActiveGroups] = useState([
    "UPSC",
    "prepBubble",
    "My Team",
    // "Alerts",
  ]);

  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic lg:mt-0 lg:w-[98%] lg:m-auto">
      <div>
        <button className="flex items-center rounded-[35px] border-2 px-2 py-1 border-[#E4E4E4] hover:border-[black] mt-4 mb-6">
          <span className="bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center">
            U
          </span>
          <p className="mx-4 text-lg  text-black font-semibold ">
            UPSC Civil Services
          </p>
          <RiArrowDropDownLine size="24px" />
        </button>
        {/* Add dropdown after suggestion */}
      </div>
      <div className="mt-8 mb-4 px-4 lg:px-2">
        <h1 className="text-[28px] text-black mb-4 font-semibold">
          {" "}
          Welcome Back, {userName}
        </h1>
        <p>Complete your daily tasks to fastrack your preparation.</p>
      </div>
      <div className="flex items-center md:flex-col md:items-start">
        <div className=" flex items-center mr-44 lg:mr-0">
          <div className="flex items-center justify-center w-36  mx-4 relative">
            <Graph
              completedTestsToday={completedTestsToday}
              allowedDailyTests={allowedDailyTests}
            />
            {Math.round(percent) == 100 ? (
              <span className="absolute top-[36%] left-[17%] text-black text-[23px] font-semibold">
                {Math.round(percent)}%
              </span>
            ) : Math.round(percent) < 10 ? (
              <span className="absolute top-[36%] left-[32%] text-black text-[23px] font-semibold">
                {Math.round(percent)}%
              </span>
            ) : (
              <span className="absolute top-[36%] left-[24%] text-black text-[23px] font-semibold">
                {Math.round(percent)}%
              </span>
            )}
          </div>
          <div className="py-8 px-4 flex flex-col  justify-end font-Inter non-italic  ">
            <span className="font-medium text-black ">Monthly Tasks</span>
            <p className="font-light text-[#303030] text-[12px]">
              {/* You marked {completedTestsToday}/{allowedDailyTests} tasks as done */}
              Regularly complete your daily tasks to hit your monthly goal.
            </p>
          </div>
        </div>

        <div className="flex lg:mx-4 lg:my-4 md:items-center">
          <div className="flex justify-center items-start px-4 py-4 md:w-1/3 md:items-center md:text-center">
            <h1 className="font-semibold text-[32px] leading-10 text-[black]">
              {completedTestsToday}/{allowedDailyTests}
            </h1>
          </div>
          <div className="flex flex-col justify-center px-4 py-2 md:w-2/3 md:mx-1 ">
            <span className="font-medium text-black ">Daily Task</span>
            <p className="flex  font-light text-[#303030] text-[12px] my-2">
              {" "}
              <img className="w-6 mr-2" src={info} alt="i" />
              Complete 4 tasks to meet the day's objective
            </p>
          </div>
        </div>
      </div>
      <DashboardDivision />

      <div className="flex md:flex-col">
        <div className="flex mx-4">
          <div className=" flex bg-[#E9F5FE] rounded-full mx-4">
            <img className="rotate-image" src={runningMan} alt="man" />
          </div>
          <div className=" mx-4 flex flex-col items-center">
            <span className="mt-4 font-medium text-black ">
              Your Doing Great! Keep Going
            </span>
            <p className="font-light text-[#303030] text-[12px] flex justify-start w-[100%]">
              Last 5 Days Completion Rate
            </p>
          </div>
        </div>
        <div className="flex ml-[30%] items-center md:hidden lg:ml-[20%]">
  {testHistory.map((isTrue, index) => (
    <div key={index} className="relative group">
      {isTrue ? (
        <img
          className="w-12 mx-2"
          src={greenTick}
          alt="Green Tick"
          data-tip="Test Taken"
          data-place="bottom"
        />
      ) : (
        <img
          className="w-12 mx-2"
          src={purpleTick}
          alt="Purple Tick"
          data-tip="No test taken"
          data-place="bottom"
        />
      )}
      <div className="opacity-0 group-hover:opacity-100 bg-black text-white text-xs rounded py-1 px-2 absolute bottom-full left-1/2 transform -translate-x-1/2 transition-opacity duration-300">
        {isTrue ? "Test Taken" : "No test taken"}
      </div>
    </div>
  ))}
</div>

      </div>
      <DashboardDivision />

      <div className="flex items-center lg:flex-col lg:items-start lg:px-2 ">
        <p className="font-semibold text-[16px] text-black mr-12">
          {" "}
          Your Active Groups
        </p>
        <div className="flex py-4 ml-6 lg:ml-0">
          {activeGroups.map((item, index) => (
            <div className="flex flex-col items-center">
              <div className="bg-[#E9F5FE] rounded-full w-24 h-24 mx-4 flex justify-center items-center hover:border-2 hover:border-[#5D44DE] cursor-pointer transition-all">
                <img className="w-10" src={`/images/grp${index}.svg`} />
              </div>
              <p className="font-light text-[#303030] text-[14px] my-2 ">
                {item}
              </p>
            </div>
          ))}
          <div className="flex flex-col items-center">
            <div className="border-2 border-dotted border-[#303030] rounded-full w-24 h-24 mx-4 flex justify-center items-center hover:border-double cursor-pointer transition-all">
              <h1 className="font-medium text-[28px] ">+</h1>
            </div>
            {/* add onClick on create */}
            <p className="font-light text-[#303030] text-[14px] my-2 ">
              Create
            </p>
          </div>
        </div>
        <div className=" rounded-[20px] bg-yellow-300 p-3 px-6 ml-8">
          Coming Soon
        </div>
      </div>
      <DashboardDivision />
      <div>
        <h1 className="font-Inter non-italic font-semibold text-black text-[28px] max-w-[473px] my-2 lg:text-[18px]">
          Todays Task
        </h1>

        <p className="mt-12 mb-4 font-semibold text-[16px] text-black">
          UPSC Civil Services is not a rocket science you can make it too
        </p>
        <div>
          <Slider {...settings}>
            <div className="bg-[#E5E5FE] rounded-[20px] px-14  py-6 text-left max-w-[341px] ">
              <div className="bg-white rounded-full w-20 my-6">
                <img src={whiteTick} alt="" />
              </div>
              <h1 className="font-semibold text-[22px] max-w-[175px] text-black my-4">
                Read daily current affairs
              </h1>
              <p className="max-w-[242px] font-light text-[14px] text-[#303030] my-8">
                Your daily dose of current affairs. These articles are covered
                from a variety of news source
              </p>
              {testCompleted ? (
                <button
                  className="bg-[#5D44DE] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                  onClick={() => {
                    openNotification("top");
                  }}
                >
                  Test Completed
                </button>
              ) : (
                <a href="https://blog.prepbubble.com/" target="_blank">
                  <button
                    className="bg-[#5D44DE] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                    // onClick={() => {
                    //   setDashboardHomeModal(true);
                    // }}
                  >
                    {/* Attempt Test */}
                    Read Current Affairs
                  </button>
                </a>
              )}
            </div>

            <div className="bg-[#E9F5FE] rounded-[20px] px-14  py-6 text-left max-w-[341px] ">
              <div
                className="bg-white rounded-full w-20 h-auto my-6 "
                id="content-carousel"
              >
                <img src={whiteTick} alt="" />
              </div>
              <h1 className="font-semibold text-[22px] max-w-[151px] text-black my-4">
                Daily current affairs test
              </h1>
              <p className="max-w-[242px] font-light text-[14px] text-[#303030] my-8">
                Test your knowledge of the news articles by answering these
                questions
              </p>
              <Link to="/test">
                <button
                  className="bg-[#24AEDA] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                  // onClick={() => {
                  //   setDashboardHomeModal(true);
                  // }}
                >
                  Attempt Test
                </button>
              </Link>
            </div>

            <div className="bg-[#FFE5DD] rounded-[20px] px-14  py-6 text-left max-w-[341px] ">
              <div
                className="bg-white rounded-full w-20 my-6 "
                id="content-carousel"
              >
                <img src={whiteTick} alt="" />
              </div>
              <h1 className="font-semibold text-[22px] max-w-[160px] text-black my-4">
                Daily chapter tests
              </h1>
              <p className="max-w-[242px] font-light text-[14px] text-[#303030] my-8">
                Boost your subject and chapter specific knowledge by attempting
                these questions
              </p>
              <Link to="/test">
                <button
                  className="bg-[#FB9C45] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                  // onClick={() => {
                  //   setDashboardHomeModal(true);
                  // }}
                >
                  Attempt Test
                </button>
              </Link>
            </div>

            <div className="bg-[#E5E5FE] rounded-[20px] px-14  py-6 text-left max-w-[341px] ">
              <div
                className="bg-white rounded-full w-20 my-6"
                id="content-carousel"
              >
                <img src={whiteTick} alt="" />
              </div>
              <h1 className="font-semibold text-[22px] max-w-[151px] text-black my-4">
                Daily full test
              </h1>
              <p className="max-w-[242px] font-light text-[14px] text-[#303030] my-8">
                Complete these tests to enhance your time management abilities
                and capacity for handling questions from various subject areas.
              </p>
              <Link to="/test">
                <button
                  className="bg-[#5D44DE] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                  // onClick={() => {
                  //   setDashboardHomeModal(true);
                  // }}
                >
                  Attempt Test
                </button>
              </Link>
            </div>
          </Slider>
        </div>
        <p className="flex  font-light text-[#303030] text-[14px]  mb-24">
          <img className="w-6 mr-6" src={info} alt="i" /> If you do 2 sub topics
          a day you can complete the entire syllabus in 100 days
        </p>
      </div>
    </div>
  );
};

export default Content;
